import Box from "@mui/material/Box";
import { ReactComponent as BancoPanLogo } from "assets/bancopan-logo.svg";

const Footer = () => {
  return (
    <Box component="footer">
      <Box
        component="div"
        display="flex"
        justifyContent="center"
        alignItems="end"
        flexDirection="column"
        p={2}
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box color="#757575" fontSize="12px" mr={2}>
            Uma empresa do
          </Box>
          <BancoPanLogo />
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
