const columnColors = {
  PDVS: { label: "PDVs", backgroundColor: "#e7f7ff" },
  BILLING: { label: "Cobrança", backgroundColor: "#ffe7e7" },
  LEADS_AND_FINANCING: {
    label: "Leads e Financiamento",
    backgroundColor: "#e7fff5",
  },
  DEALS: { label: "Anúncios", backgroundColor: "#f3e7ff" },
};

export default columnColors;
