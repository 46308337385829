import { createStore, compose, combineReducers } from "redux";
import { reactotron } from "../reactotron";
import userStore from "./userStore";

const reducers = combineReducers({
  userStore,
});

const middlewares = [];

if (process.env.NODE_ENV === "development") {
  const reactotronMiddleware = reactotron.createEnhancer();
  middlewares.push(reactotronMiddleware);
}

const store = createStore(reducers, compose(...middlewares));

export default store;
