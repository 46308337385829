import React, { useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import TableSortLabel from "@mui/material/TableSortLabel";
import TablePagination from "@mui/material/TablePagination";
import reduceTableValues from "helpers/reduceTableValues";
import Colors from "utils/colors";
import { visuallyHidden } from "@mui/utils";
import fixedColumnsConfig from "utils/fixedColumnsConfig";

const buildRowData = (array: any, total = false) => {
  const allData = [...array];
  if (total) {
    const totalList = [];
    totalList.push({
      name: "Total",
      id: "total",
      numberOfDealersActive: reduceTableValues(
        allData,
        "numberOfDealersActive"
      ),
      numberOfDealersActiveInDay: reduceTableValues(
        allData,
        "numberOfDealersActiveInDay"
      ),
      numberOfDealerSuspended: reduceTableValues(
        allData,
        "numberOfDealerSuspended"
      ),
      numberOfDealerDelayed: reduceTableValues(
        allData,
        "numberOfDealerDelayed"
      ),
      proposal: reduceTableValues(allData, "proposal"),
      financing: reduceTableValues(allData, "financing"),
      financingPreAnalyses: reduceTableValues(allData, "financingPreAnalyses"),
      financingVoucher: reduceTableValues(allData, "financingVoucher"),
      achievedValue: reduceTableValues(allData, "achievedValue"),
      billingDelayed: reduceTableValues(allData, "billingDelayed"),
      billingPaid: reduceTableValues(allData, "billingPaid"),
      billingToReceived: reduceTableValues(allData, "billingToReceived"),
      wallet: reduceTableValues(allData, "wallet"),
      numberOfDeals: reduceTableValues(allData, "numberOfDeals"),
      numberOfDealsPublished: reduceTableValues(
        allData,
        "numberOfDealsPublished"
      ),
      numberOfDealerWithIssues: reduceTableValues(
        allData,
        "numberOfDealerWithIssues"
      ),
      averageScore: reduceTableValues(allData, "averageScore", true),
      dealerWithoutUpdateIn10Days: reduceTableValues(
        allData,
        "dealerWithoutUpdateIn10Days"
      ),
      dealWithoutPhotos: reduceTableValues(allData, "dealWithoutPhotos"),
      pctNewDeals: reduceTableValues(allData, "pctNewDeals", true),
    });
    return totalList;
  }
  return allData;
};

interface Data {
  name: string;
  id: number | string;
  numberOfDealersActive: number;
  numberOfDealersActiveInDay: number;
  numberOfDealerSuspended: number;
  numberOfDealerDelayed: number;
  proposal: number;
  financing: number;
  financingPreAnalyses: number;
  financingVoucher: number;
  achievedValue: number;
  billingDelayed: number;
  billingPaid: number;
  billingToReceived: number;
  wallet: number;
  numberOfDeals: number;
  numberOfDealsPublished: number;
  numberOfDealerWithIssues: number;
  averageScore: number;
  dealerWithoutUpdateIn10Days: number;
  dealWithoutPhotos: number;
  pctNewDeals: number;
}

type Order = "asc" | "desc";

const PerformaceStoreTable = ({
  currentView,
  performaceData,
  mobisalesParams,
  setBreadcrumbSchema,
  setMobisalesParams,
  breadcrumb,
}: any) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>(
    "numberOfDealersActive"
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRequestSort = (_: any, property: any) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property: any) => (event: any) => {
    handleRequestSort(event, property);
  };

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
  ) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleClickEnterViewMobi = (
    personId: number | string,
    personName: string | number
  ) => {
    switch (currentView) {
      case "OWNER":
        setBreadcrumbSchema((prevState: any) => [
          ...prevState,
          {
            label: `${personName} (Gerente)`,
            currentParams: {
              managerId: personId,
              view: "MANAGER",
            },
          },
        ]);
        return setMobisalesParams((prevState: any) => ({
          ...prevState,
          managerId: personId,
          view: "MANAGER",
        }));
      //MOBI_STRUCTURE
      case "MANAGER":
        setBreadcrumbSchema((prevState: any) => [
          ...prevState,
          {
            label: `${personName} (Supervisor)`,
            currentParams: {
              ...mobisalesParams,
              supervisorId: personId,
              view: "SUPERVISOR",
            },
          },
        ]);
        return setMobisalesParams((prevState: any) => ({
          ...prevState,
          supervisorId: personId,
          view: "SUPERVISOR",
        }));
      case "SUPERVISOR":
        setBreadcrumbSchema((prevState: any) => [
          ...prevState,
          {
            label: `${personName} (Especialista)`,
            currentParams: {
              ...mobisalesParams,
              specialistId: personId,
              view: "SPECIALIST",
            },
          },
        ]);
        return setMobisalesParams((prevState: any) => ({
          ...prevState,
          specialistId: personId,
          view: "SPECIALIST",
        }));
      default:
        return;
    }
  };

  const handleClickEnterViewBank = (
    personId: number | string,
    personName: string | number
  ) => {
    switch (currentView) {
      case "OWNER":
        setBreadcrumbSchema((prevState: any) => [
          ...prevState,
          {
            label: `${personName} (Superintendent)`,
            currentParams: {
              superintendentId: personId,
              view: "SUPERINTENDENT",
            },
          },
        ]);
        return setMobisalesParams((prevState: any) => ({
          ...prevState,
          superintendentId: personId,
          view: "SUPERINTENDENT",
        }));
      //MOBI_STRUCTURE
      case "SUPERINTENDENT":
        setBreadcrumbSchema((prevState: any) => [
          ...prevState,
          {
            label: `${personName} (Regional)`,
            currentParams: {
              ...mobisalesParams,
              regionalId: personId,
              view: "REGIONAL",
            },
          },
        ]);
        return setMobisalesParams((prevState: any) => ({
          ...prevState,
          regionalId: personId,
          view: "REGIONAL",
        }));
      case "REGIONAL":
        setBreadcrumbSchema((prevState: any) => [
          ...prevState,
          {
            label: `${personName} (Plataforma)`,
            currentParams: {
              ...mobisalesParams,
              platformId: personId,
              view: "PLATFORM",
            },
          },
        ]);
        return setMobisalesParams((prevState: any) => ({
          ...prevState,
          platformId: personId,
          view: "PLATFORM",
        }));
      case "PLATFORM":
        setBreadcrumbSchema((prevState: any) => [
          ...prevState,
          {
            label: `${personName} (Operador)`,
            currentParams: {
              ...mobisalesParams,
              operatorId: personId,
              view: "OPERATOR",
            },
          },
        ]);
        return setMobisalesParams((prevState: any) => ({
          ...prevState,
          operatorId: personId,
          view: "OPERATOR",
        }));
      default:
        return;
    }
  };

  const columns: any = [
    {
      field: "name",
      headerName: "Nome",
      width: 150,
      renderCell: ({
        id,
        value,
      }: {
        id: number | string;
        value: string | number;
      }) => (
        <Box
          component="div"
          color="#4443BC"
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (id === "total") return;
            mobisalesParams.structure === "MOBI"
              ? handleClickEnterViewMobi(id, value)
              : handleClickEnterViewBank(id, value);
          }}
        >
          {value}
        </Box>
      ),
    },
    {
      field: "numberOfDealersActive",
      headerName: "Ativos",
      backgroundColor: fixedColumnsConfig.PDVS.backgroundColor,
      // width: 150,
    },
    {
      field: "numberOfDealersActiveInDay",
      headerName: "Em dia",
      type: "number",
      headerAlign: "left",
      backgroundColor: fixedColumnsConfig.PDVS.backgroundColor,
      width: 80,
    },
    // {
    //   field: "numberOfDealerSuspended",
    //   headerName: "Suspensos",
    //   type: "number",
    //   headerAlign: "left",
    //   backgroundColor: fixedColumnsConfig.PDVS.backgroundColor,
    //   renderCell: ({ value }: { value: string | number }) => (
    //     <div style={{ color: Colors.dangerous.medium }}>
    //       {value.toLocaleString("pt-BR")}
    //     </div>
    //   ),
    //   // width: 110,
    // },
    {
      field: "numberOfDealerDelayed",
      headerName: "Em atraso",
      type: "number",
      headerAlign: "left",
      backgroundColor: fixedColumnsConfig.PDVS.backgroundColor,
      renderCell: ({ value }: { value: string | number }) => (
        <div style={{ color: Colors.dangerous.medium }}>
          {value.toLocaleString("pt-BR")}
        </div>
      ),
      // width: 110,
    },
    {
      field: "achievedValue",
      headerName: "Atingido",
      type: "number",
      headerAlign: "left",
      backgroundColor: fixedColumnsConfig.BILLING.backgroundColor,
      // width: 110,
    },
    {
      field: "billingPaid",
      headerName: "Pago",
      type: "number",
      headerAlign: "left",
      backgroundColor: fixedColumnsConfig.BILLING.backgroundColor,
      renderCell: ({ value }: { value: string | number }) => (
        <div style={{ color: Colors.primary.medium }}>
          {value.toLocaleString("pt-BR")}
        </div>
      ),
      // width: 110,
    },
    {
      field: "billingDelayed",
      headerName: "Em atraso",
      type: "number",
      headerAlign: "left",
      backgroundColor: fixedColumnsConfig.BILLING.backgroundColor,
      renderCell: ({ value }: { value: string | number }) => (
        <div style={{ color: Colors.dangerous.medium }}>
          {value.toLocaleString("pt-BR")}
        </div>
      ),
      width: 110,
    },
    {
      field: "billingToReceived",
      headerName: "A receber",
      type: "number",
      headerAlign: "left",
      backgroundColor: fixedColumnsConfig.BILLING.backgroundColor,
      renderCell: ({ value }: { value: string | number }) => (
        <div>{value.toLocaleString("pt-BR")}</div>
      ),
      width: 110,
    },
    {
      field: "wallet",
      headerName: "Carteira",
      type: "number",
      headerAlign: "left",
      backgroundColor: fixedColumnsConfig.BILLING.backgroundColor,
      renderCell: ({ value }: { value: string | number }) => (
        <div>{value.toLocaleString("pt-BR")}</div>
      ),
      // width: 110,
    },
    {
      field: "proposal",
      headerName: "Leads",
      type: "number",
      headerAlign: "left",
      backgroundColor: fixedColumnsConfig.LEADS_AND_FINANCING.backgroundColor,
      // width: 110,
    },
    {
      field: "financing",
      headerName: "Financiamento",
      type: "number",
      headerAlign: "left",
      backgroundColor: fixedColumnsConfig.LEADS_AND_FINANCING.backgroundColor,
      // width: 110,
    },
    // {
    //   field: "financingPreAnalyses",
    //   headerName: "PA",
    //   type: "number",
    //   headerAlign: "left",
    //   backgroundColor: fixedColumnsConfig.LEADS_AND_FINANCING.backgroundColor,
    //   // width: 110,
    // },
    // {
    //   field: "financingVoucher",
    //   headerName: "Voucher",
    //   type: "number",
    //   headerAlign: "left",
    //   backgroundColor: fixedColumnsConfig.LEADS_AND_FINANCING.backgroundColor,
    //   // width: 110,
    // },
    {
      field: "numberOfDeals",
      headerName: "Anúncios",
      type: "number",
      headerAlign: "left",
      backgroundColor: fixedColumnsConfig.DEALS.backgroundColor,
      // width: 110,
    },
    {
      field: "numberOfDealsPublished",
      headerName: "Publicados",
      type: "number",
      headerAlign: "left",
      backgroundColor: fixedColumnsConfig.DEALS.backgroundColor,
      // width: 110,
    },
    {
      field: "numberOfDealerWithIssues",
      headerName: "PDVS com Problemas",
      type: "number",
      headerAlign: "left",
      backgroundColor: fixedColumnsConfig.DEALS.backgroundColor,
      renderCell: ({ value }: { value: string | number }) => (
        <div style={{ color: Colors.dangerous.medium }}>
          {value.toLocaleString("pt-BR")}
        </div>
      ),
      // width: 110,
    },
    {
      field: "averageScore",
      headerName: "Score",
      type: "number",
      headerAlign: "left",
      backgroundColor: fixedColumnsConfig.DEALS.backgroundColor,
      renderCell: ({ value }: { value: string | number }) => (
        <div>{Number(value).toFixed(0)}</div>
      ),
      // width: 110,
    },
    {
      field: "dealerWithoutUpdateIn10Days",
      headerName: "Sem atualização (10 dias)",
      type: "number",
      headerAlign: "left",
      backgroundColor: fixedColumnsConfig.DEALS.backgroundColor,
      renderCell: ({ value }: { value: string | number }) => (
        <div style={{ color: Colors.dangerous.medium }}>
          {value.toLocaleString("pt-BR")}
        </div>
      ),
      width: 160,
    },
    {
      field: "dealWithoutPhotos",
      headerName: "Sem Fotos",
      type: "number",
      headerAlign: "left",
      backgroundColor: fixedColumnsConfig.DEALS.backgroundColor,
      renderCell: ({ value }: { value: string | number }) => (
        <div
          style={{ color: value > 1 ? Colors.dangerous.medium : "#000000de" }}
        >
          {value.toLocaleString("pt-BR")}
        </div>
      ),
      width: 110,
    },
    {
      field: "pctNewDeals",
      headerName: "% novos anúncios",
      type: "number",
      headerAlign: "left",
      backgroundColor: fixedColumnsConfig.DEALS.backgroundColor,
      renderCell: ({ value }: { value: number }) => (
        <div>
          {Number(value / 100).toLocaleString("pt-br", {
            style: "percent",
            // minimumFractionDigits: 2,
          })}
        </div>
      ),
      width: 110,
    },
  ];

  return (
    <Box p={"0px 16px"}>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <Typography variant="subtitle2" component="div">
          Performance
        </Typography>
        {breadcrumb}
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell align="center" colSpan={1}>
                  -
                </TableCell>
                <TableCell
                  align="center"
                  colSpan={3}
                  style={{
                    background: fixedColumnsConfig.PDVS.backgroundColor,
                    color: Colors.gray.medium,
                    fontWeight: 600,
                  }}
                >
                  PDVs
                </TableCell>
                <TableCell
                  align="center"
                  colSpan={5}
                  style={{
                    background: fixedColumnsConfig.BILLING.backgroundColor,
                    color: Colors.gray.medium,
                    fontWeight: 600,
                  }}
                >
                  Cobrança
                </TableCell>
                <TableCell
                  align="center"
                  colSpan={2}
                  style={{
                    background:
                      fixedColumnsConfig.LEADS_AND_FINANCING.backgroundColor,
                    color: Colors.gray.medium,
                    fontWeight: 600,
                  }}
                >
                  Leads e Financiamento
                </TableCell>
                <TableCell
                  align="center"
                  colSpan={7}
                  style={{
                    background: fixedColumnsConfig.DEALS.backgroundColor,
                    color: Colors.gray.medium,
                    fontWeight: 600,
                  }}
                >
                  Anúncios
                </TableCell>
              </TableRow>
              <TableRow>
                {columns.map((column: any) => (
                  <TableCell
                    key={column.field}
                    align={column.align}
                    style={{
                      minWidth: column.width,
                      background: column?.backgroundColor,
                      color: Colors.gray.medium,
                      fontWeight: 600,
                    }}
                  >
                    <TableSortLabel
                      active={orderBy === column.field}
                      direction={orderBy === column.field ? order : "asc"}
                      onClick={createSortHandler(column.field)}
                    >
                      {column.headerName}
                      {orderBy === column.field ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(
                buildRowData(performaceData),
                getComparator(order, orderBy)
              )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      {columns.map((column: any) => {
                        const value = row[column.field];
                        if (
                          value !== "não atribuido" &&
                          typeof column.renderCell === "function"
                        ) {
                          return (
                            <TableCell key={column.field} align={column.align}>
                              <Box
                                component="div"
                                color="#4443BC"
                                style={{ cursor: "pointer" }}
                                onClick={() => {}}
                              >
                                {column.renderCell({
                                  id: row.id,
                                  value,
                                })}
                              </Box>
                            </TableCell>
                          );
                        }
                        return (
                          <TableCell key={column.field} align={column.align}>
                            {value?.toLocaleString("pt-BR")}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              {buildRowData(performaceData, true).map((row) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-2}
                    key={row.code}
                    style={{ backgroundColor: "#f5f5f5" }}
                  >
                    {columns.map((column: any) => {
                      const value = row[column.field];
                      if (
                        column.field !== "name" &&
                        typeof column.renderCell === "function"
                      ) {
                        return (
                          <TableCell key={column.field} align={column.align}>
                            {column.renderCell({
                              id: row.id,
                              value,
                            })}
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell key={column.field} align={column.align}>
                          {value?.toLocaleString("pt-BR")}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={buildRowData(performaceData).length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default PerformaceStoreTable;
