import { create } from "apisauce";
import NProgress from "nprogress";
import { getToken } from "auth/AuthService";
// define the api
const api = create({
  baseURL: "/",
  headers: { accept: "*/*" },
  // timeout: 30 * 1000, // 10 seconds
});

const embeddedAuthToken = () => {
  const token = getToken();
  if (token) {
    api.setHeader("Authorization", `Bearer ${token}`);
  }
};

// #url - the relative path to the API
// #params - Object - query string variables
// #axiosConfig - Object - config passed along to the axios request
// #data - Object - the object jumping the wire

const request = {
  get: (url: string, params: any, axiosConfig?: any) => {
    if (!url) {
      throw new Error("Missing url parameter");
    }
    NProgress.start();

    return api.get(url, params, axiosConfig).then((data) => {
      NProgress.done();

      return data;
    });
  },
  post: (url: string, data: any, axiosConfig?: any) => {
    if (!url || !data) {
      throw new Error("Missing URL parameter and Missing DATA parameter ");
    }
    NProgress.start();
    return api.post(url, data, axiosConfig).then((data) => {
      NProgress.done();

      return data;
    });
  },
  put: (url: string, data: any, axiosConfig?: any) => {
    if (!url || !data) {
      throw new Error("Missing URL parameter and Missing DATA parameter ");
    }
    NProgress.start();

    return api.put(url, data, axiosConfig).then((data) => {
      NProgress.done();
      return data;
    });
  },
  delete: (url: string, params: any, axiosConfig?: any) => {
    if (!url) {
      throw new Error("Missing url parameter");
    }
    NProgress.start();

    return api.delete(url, params, axiosConfig).then((data) => {
      NProgress.done();
      return data;
    });
  },
  patch: (url: string, data: any, axiosConfig?: any) => {
    if (!url || !data) {
      throw new Error("Missing URL parameter and Missing DATA parameter ");
    }
    NProgress.start();

    return api.patch(url, data, axiosConfig).then((data) => {
      NProgress.done();
      return data;
    });
  },
  head: (url: string, params: any, axiosConfig?: any) => {
    if (!url) {
      throw new Error("Missing url parameter");
    }
    NProgress.start();

    return api.head(url, params, axiosConfig).then((data) => {
      NProgress.done();
      return data;
    });
  },
  link: (url: string, params: any, axiosConfig?: any) => {
    if (!url) {
      throw new Error("Missing url parameter");
    }
    NProgress.start();

    return api.link(url, params, axiosConfig).then((data) => {
      NProgress.done();
      return data;
    });
  },
};
export { api, embeddedAuthToken };
export default request;
