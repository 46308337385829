import Request from "helpers/Request";
import getEnvVarByName from "helpers/getEnvVarByName";

const domain = getEnvVarByName("API_MOBISALES_BASE_URL");

export default class MobisalesServices {
  static getMobisalesData = async (params: any) => {
    const response = await Request.post(`${domain}/view/v1.0`, params);
    if (response.ok) {
      return { success: true, data: response.data };
    }
    return { success: false, data: null };
  };

  static getLevelInfos = async () => {
    const response = await Request.get(`${domain}/view/v1.0/view-level`, {});
    if (response.ok) {
      return { success: true, data: response.data };
    }
    return { success: false, data: null };
  };
}
