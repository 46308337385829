import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#556cd6",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: red.A400,
    },
  },
  typography: {
    fontFamily: "Poppins Regular",
    subtitle1: {
      fontSize: 24,
      fontFamily: "Poppins Bold",
    },
    subtitle2: {
      fontSize: 20,
      fontFamily: "Poppins Bold",
      padding: "10px",
    },
  },
});

export default theme;
