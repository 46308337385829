import React from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import Divider from "@mui/material/Divider";
import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import Colors from "utils/colors";
import { logout } from "auth/AuthService";

const CurrentUserInfos = () => {
  const { currentUser } = useSelector((state: any) => state.userStore);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "popover" : undefined;

  return (
    <div>
      <Box component="div" display="flex" alignItems="center" m="0px 26px">
        <Box component="div" mr="8px">
          <Box component="div" fontSize="12px">
            Olá, {currentUser.firstName} {currentUser.lastName}
          </Box>
          <Box
            component="div"
            fontSize="12px"
            textAlign="end"
            color={Colors.primary.medium}
          >
            -
          </Box>
        </Box>
        <ButtonBase onClick={handleClick}>
          <ExpandMoreIcon />
        </ButtonBase>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box component="div" p={2} width={"300px"}>
          <Typography>
            {currentUser.firstName} {currentUser.lastName}
          </Typography>
          <Box component="div" fontSize={"12px"}>
            {currentUser.email}
          </Box>

          <Box component="div" fontSize={"12px"} mt={1}>
            ID: {currentUser.attributes?.usersite_id?.[0]?.split(".")?.[0]}
          </Box>
        </Box>
        <Divider />
        <Box
          component="div"
          display="flex"
          alignItems="center"
          justifyContent="end"
          p={1}
        >
          <ButtonBase onClick={logout}>
            <Box component="div" display="flex" alignItems="center" p={"4px"}>
              <PowerSettingsNewIcon style={{ fontSize: 14, marginRight: 4 }} />
              Sair
            </Box>
          </ButtonBase>
        </Box>
      </Popover>
    </div>
  );
};

export default CurrentUserInfos;
