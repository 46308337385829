const reduceValue = (array: any, name: string, isAvarage = false) => {
  const clearArray = array
    .map((el: any) => {
      if (el?.[name] === undefined) {
        return null;
      }
      return el?.[name];
    })
    .filter((el: any) => el !== null);
  if (!clearArray.length) {
    clearArray.push(0);
  }
  if (isAvarage) {
    return Math.round(
      clearArray.reduce((previousValue: any, currentValue: any) => {
        return previousValue + currentValue;
      }) / clearArray.length
    );
  }
  return clearArray.reduce((previousValue: any, currentValue: any) => {
    return previousValue + currentValue;
  });
};

export default reduceValue;
