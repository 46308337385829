import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import Header from "./components/HeaderBase";
import Footer from "./components/FooterBase";

const LayoutComponent = (props: any) => {
  return (
    <>
      <Header />
      <Box
        component="main"
        style={{ background: "#E5E5E5", minHeight: "calc(100vh - 120px)" }}
      >
        <Outlet />
      </Box>
      <Footer />
    </>
  );
};

export default LayoutComponent;
