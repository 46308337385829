import Keycloak from "keycloak-js";

const keycloak = new Keycloak(
  process.env.REACT_APP_ENV === "DEVELOPMENT"
    ? "/keycloak-test.json"
    : "/keycloak.json"
);

let autheticated = false;

let user = {};

const login = async () => {
  try {
    autheticated = await keycloak
      .init({
        onLoad: "login-required",
        checkLoginIframe: false,
        // redirectUri: window.location.origin + "/home",
      })
      .then((autheticated) => {
        return autheticated;
      })
      .catch(() => {
        return false;
      });
    return autheticated;
  } catch (error) {
    return false;
  }
};

const getProfile = async () => {
  try {
    await keycloak
      .loadUserProfile()
      .then((profile) => {
        user = profile;
      })
      .catch(() => {
        console.log("error");
      });
    return user;
  } catch (error) {
    return false;
  }
};

const getToken = () => {
  return keycloak.token;
};

const isLogged = () => {
  const token = getToken();
  return !!token;
};

const logout = () => {
  keycloak.logout({
    redirectUri: window.location.origin,
  });
};

export { login, getProfile, getToken, isLogged, logout };
