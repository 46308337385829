//ANTIGOS NOMES DE CORES
const orange = {
  light: "#ffb366",
  medium: "#ff8000",
  dark: "#cc6600",
};
const purple = {
  light: "#c44cff",
  medium: "#9900e6",
  dark: "#660099",
};
const blue = {
  light: "#00aaff",
  medium: "#0080ff",
  dark: "#0059b3",
};
const red = {
  light: "#ff6666",
  medium: "#e61300",
  dark: "#990d00",
};
const green = {
  light: "#7acc7a",
  medium: "#14cc14",
  dark: "#198019",
};

//NOVOS NOMES DE CORES

const stars = {
  light: "#FFD64D",
  medium: "#FFC200",
  dark: "#B38600",
};

const warning = {
  light: "#ffb366",
  medium: "#FF7700",
  dark: "#cc6600",
};
const details = {
  light: "#c44cff",
  medium: "#AA00EF",
  dark: "#660099",
};
const information = {
  light: "#00aaff",
  medium: "#0080ff",
  dark: "#0059b3",
};
const dangerous = {
  light: "#ff6666",
  medium: "#FD0000",
  dark: "#990d00",
};
const success = {
  light: "#7acc7a",
  medium: "#00D100",
  dark: "#198019",
};
const gray = {
  dark: "#333333",
  medium: "#808080",
  light: "#b3b3b3",
  lighter: "#cccccc",
  nearWhite: "#e6e6e6",
};
const primary = {
  light: "#4ab3ba",
  medium: "#00B0AA",
  dark: "#00666e",
};
const secondary = {
  light: gray.light,
  medium: gray.medium,
  dark: gray.dark,
};

const textArea = {
  normal: `#e6e6e6`,
};

const colors = {
  orange,
  purple,
  blue,
  red,
  green,
  primary,
  textArea,
  secondary,
  warning,
  details,
  stars,
  information,
  dangerous,
  success,
  gray,
  text: {
    normal: gray.dark,
    disabled: gray.medium,
  },
};

export default colors;
