import AuthComponent from "auth/AuthComponent";
import Layout from "layout/Layout";
import NotFound from "pages/404";
import Home from "pages/Home";

const INITIAL_PATH = "/";

const authChildren = [
  {
    path: INITIAL_PATH,
    element: <Home />,
  },
];

const authRoute = [
  {
    path: INITIAL_PATH,
    element: (
      <AuthComponent>
        <Layout />
      </AuthComponent>
    ),
    children: authChildren,
  },
];

const route404 = [
  {
    path: "*",
    element: <NotFound />,
  },
];

const routes = [...authRoute, ...route404];

export default routes;
