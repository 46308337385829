import React, { useEffect, useCallback } from "react";
import { login, getProfile } from "./AuthService";
import { useDispatch } from "react-redux";
import { Creators as UserCreators } from "store/userStore";
import { embeddedAuthToken } from "helpers/Request";
import RenderLoadingAuthentication from "components/RenderLoadingAuthentication";

const AuthComponent = ({ children }: any) => {
  const dispatch = useDispatch();
  const [isLogged, setLogged] = React.useState(false);

  const tryLogin = useCallback(async () => {
    const isAuthenticated = await login();
    if (isAuthenticated) {
      setLogged(true);
      embeddedAuthToken();
      const currentUserProfile = await getProfile();
      dispatch(UserCreators.setCurrentUserData(currentUserProfile));
    }
  }, [dispatch]);

  useEffect(() => {
    tryLogin();
  }, [tryLogin]);

  if (!isLogged) {
    return <RenderLoadingAuthentication />;
  }

  return <>{children}</>;
};

export default AuthComponent;
