import { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import mobisalesServices from "services/mobisalesServices";
import MobiStructure from "./components/MobiStructureTable";
import BankStructure from "./components/BankStructureTable";

//"id": 2517020,
//"name": "Priscila Alves",
//"numberOfDealersActive": 6510,
//"numberOfDealersActiveInDay": 4592,
//"numberOfDealerSuspended": 542,
//"numberOfDealerDelayed": 1922,
//"billingDelayed": 1505495.4,
//"billingPaid": 880324.06,
//"billingToReceived": 1028285.6,
//"wallet": 3080646,
//"numberOfDeals": 123640,
//"numberOfDealsPublished": 113102,
//"numberOfDealerWithIssues": 688,
//"averageScore": 4.633721,
//"dealerWithoutUpdateIn10Days": 2489,
//"dealWithoutPhotos": 585,
//"pctNewDeals": 57.81055

//MOBI STRUCTURE
//OWNER
//MANAGER -> managerID
//SUPERVISOR -> managerID && supervisorID
//SPECIALIST -> managerID && supervisorID && specialistID

//BANK STRUCTURE
//OWNER
//SUPERINTENDENT -> superintendentId
//REGIONAL -> superintendentId && platformId
//PLATFORM -> superintendentId && platformId && regionalId
//OPERATOR -> superintendentId && regionalId && operatorId

interface ParamsType {
  managerId?: number | null;
  operatorId?: number | null;
  regionalId?: number | null;
  specialistId?: number | null;
  structure?: string;
  superintendentId?: number | null;
  supervisorId?: number | null;
  view?: string | null;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value?: string;
}

const INITIAL_MOBISALES_STATE = [{ label: "Brasil", currentParams: {} }];

const INITIAL_MOBISALES_DATA_STATE = {
  itens: [],
  view: null,
};

const Home = () => {
  const [levelInfos, setLevelInfos] = useState({ view: null, structure: null });
  const [mobisalesData, setMobisalesData] = useState<any>(
    INITIAL_MOBISALES_DATA_STATE
  );
  const [mobisalesDataLoading, setMobisalesDataLoading] = useState<any>(true);
  const [breadcrumbSchema, setBreadcrumbSchema] = useState<any>(
    INITIAL_MOBISALES_STATE
  );
  const [mobisalesParams, setMobisalesParams] = useState<ParamsType>({});

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setMobisalesParams((prevState) => ({
      view: levelInfos.view,
      structure: newValue,
    }));
    setMobisalesData(INITIAL_MOBISALES_DATA_STATE);
    setBreadcrumbSchema(INITIAL_MOBISALES_STATE);
  };

  const getMobisalesData = useCallback(async () => {
    setMobisalesDataLoading(true);
    const response: any = await mobisalesServices.getMobisalesData(
      mobisalesParams
    );
    if (response.success) {
      setMobisalesData(response.data);
    } else {
      setMobisalesData([]);
    }
    setMobisalesDataLoading(false);
  }, [mobisalesParams]);

  const a11yProps = (index: string) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      value: index,
    };
  };

  useEffect(() => {
    mobisalesServices
      .getLevelInfos()
      .then(
        (resp: {
          success: boolean;
          data: { structure: string; view: string } | any;
        }) => {
          setLevelInfos({
            structure: resp.data.structure!,
            view: resp.data.view!,
          });
          setMobisalesParams({
            structure: resp.data.structure!,
            view: resp.data.view!,
          });
        }
      );
  }, []);

  useEffect(() => {
    if (mobisalesParams?.structure) {
      getMobisalesData();
    }
  }, [getMobisalesData, mobisalesParams]);

  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <>{children}</>}
      </div>
    );
  };

  return (
    <div style={{ display: "flow-root" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }} m={2}>
        <Tabs
          value={mobisalesParams.structure}
          onChange={handleChangeTab}
          aria-label="basic tabs example"
        >
          <Tab label="Mobiauto" {...a11yProps("MOBI")} />
          <Tab label="Banco Pan" {...a11yProps("BANK")} />
        </Tabs>
      </Box>

      <TabPanel value={mobisalesParams.structure} index={"MOBI"}>
        <MobiStructure
          mobisalesDataLoading={mobisalesDataLoading}
          mobisalesData={mobisalesData}
          mobisalesParams={mobisalesParams}
          setBreadcrumbSchema={setBreadcrumbSchema}
          setMobisalesParams={setMobisalesParams}
          breadcrumb={
            <Box component="div" p={"0px 10px 10px 10px"}>
              <Stack spacing={2}>
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  {breadcrumbSchema.map((breadcrumb: any, index: number) => {
                    if (index === breadcrumbSchema.length - 1) {
                      return (
                        <Typography
                          key={index}
                          variant="caption"
                          color="#757575"
                        >
                          {breadcrumb.label}
                        </Typography>
                      );
                    }
                    return (
                      <Link
                        key={index}
                        underline="hover"
                        variant="caption"
                        color="#757575"
                        onClick={() => {
                          setMobisalesParams({
                            ...breadcrumb.currentParams,
                            structure: "MOBI",
                          });
                          setBreadcrumbSchema(
                            breadcrumbSchema.slice(0, index + 1)
                          );
                        }}
                      >
                        {breadcrumb.label}
                      </Link>
                    );
                  })}
                </Breadcrumbs>
              </Stack>
            </Box>
          }
        />
      </TabPanel>

      <TabPanel value={mobisalesParams.structure} index={"BANK"}>
        <BankStructure
          mobisalesDataLoading={mobisalesDataLoading}
          mobisalesData={mobisalesData}
          mobisalesParams={mobisalesParams}
          setBreadcrumbSchema={setBreadcrumbSchema}
          setMobisalesParams={setMobisalesParams}
          breadcrumb={
            <Box component="div" p={"0px 10px 10px 10px"}>
              <Stack spacing={2}>
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  {breadcrumbSchema.map((breadcrumb: any, index: number) => {
                    if (index === breadcrumbSchema.length - 1) {
                      return (
                        <Typography
                          key={index}
                          variant="caption"
                          color="#757575"
                        >
                          {breadcrumb.label}
                        </Typography>
                      );
                    }
                    return (
                      <Link
                        key={index}
                        underline="hover"
                        variant="caption"
                        color="#757575"
                        onClick={() => {
                          setMobisalesParams({
                            ...breadcrumb.currentParams,
                            structure: "BANK",
                          });
                          setBreadcrumbSchema(
                            breadcrumbSchema.slice(0, index + 1)
                          );
                        }}
                      >
                        {breadcrumb.label}
                      </Link>
                    );
                  })}
                </Breadcrumbs>
              </Stack>
            </Box>
          }
        />
      </TabPanel>
    </div>
  );
};

export default Home;
