import { Provider } from "react-redux";
import { BrowserRouter, useRoutes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import store from "store";
import routes from "config/routes";

function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <BrowserRouter>
          <Routers />
        </BrowserRouter>
      </Provider>
    </QueryClientProvider>
  );
}

function Routers() {
  let element = useRoutes([...routes]);

  return element;
}

export default App;
