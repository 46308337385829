import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CurrentUserInfos from "./components/CurrentUserInfos";
import { ReactComponent as MobiLogo } from "assets/mobiauto-logo.svg";

const Header = () => {
  return (
    <Box
      component="header"
      bgcolor="#FFF"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      height={60}
    >
      <Link to="/">
        <Box component="div" padding="11px 20px">
          <MobiLogo />
        </Box>
      </Link>
      <Box
        component="div"
        display="flex"
        flexGrow={1}
        alignItems="center"
        justifyContent={"space-between"}
        style={{
          borderLeft: "1px solid #bcbcbc",
          height: "100%",
          paddingLeft: "16px",
        }}
      >
        <Typography variant="subtitle1" component="div">
          Telemetria
        </Typography>
        <CurrentUserInfos />
      </Box>
    </Box>
  );
};

export default Header;
