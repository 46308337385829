import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  setCurrentUserData: ["userData"],
});

export const INITIAL_STATE = {
  currentUser: {
    attributes: {
      apps: [],
      locale: [],
      usersite_id: [],
    },
    email: "",
    emailVerified: false,
    firstName: "",
    lastName: "",
    username: "",
  },
};

export const setCurrentUserData = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    currentUser: action.userData,
  };
};

export const HANDLERS = {
  [Types.SET_CURRENT_USER_DATA]: setCurrentUserData,
};
export default createReducer(INITIAL_STATE, HANDLERS);
