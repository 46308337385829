import React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import ActiveStoreTable from "../ActiveStoreTable";
import OverdueStoreTable from "../OverdueStoreTable";
import PerformaceStoreTable from "../PerformaceStoreTable";

const MobiStructureTable = ({
  mobisalesDataLoading,
  mobisalesData,
  mobisalesParams,
  setBreadcrumbSchema,
  setMobisalesParams,
  breadcrumb,
}: any) => {
  return (
    <>
      {mobisalesDataLoading && (
        <Box component="div" p={2}>
          <Skeleton variant="rectangular" width={"100%"} height={500} />
        </Box>
      )}

      {!mobisalesDataLoading && !!mobisalesData?.itens?.length && (
        <PerformaceStoreTable
          currentView={mobisalesData.view}
          performaceData={mobisalesData.itens}
          mobisalesParams={mobisalesParams}
          setBreadcrumbSchema={setBreadcrumbSchema}
          setMobisalesParams={setMobisalesParams}
          breadcrumb={breadcrumb}
        />
      )}

      {!mobisalesDataLoading && !!mobisalesData?.problemDealers?.length && (
        <OverdueStoreTable
          overdueStoreData={mobisalesData?.problemDealers}
          breadcrumb={breadcrumb}
        />
      )}

      {!mobisalesDataLoading &&
        !!mobisalesData?.withoutProblemDealers?.length && (
          <ActiveStoreTable
            activeStoreData={mobisalesData?.withoutProblemDealers}
            breadcrumb={breadcrumb}
          />
        )}
    </>
  );
};

export default MobiStructureTable;
